@import "~antd/dist/reset.css";

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: "OpenSansItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-Italic.ttf);
}
@font-face {
  font-family: "OpenSansLight";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-Light.ttf);
}
@font-face {
  font-family: "OpenSansLightItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-LightItalic.ttf);
}
@font-face {
  font-family: "OpenSansSemiBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-SemiBold.ttf);
}
@font-face {
  font-family: "OpenSansSemiBoldItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "OpenSansBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: "OpenSansBoldItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-BoldItalic.ttf);
}

@font-face {
  font-family: "OpenSansExtraBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-ExtraBold.ttf);
}
@font-face {
  font-family: "OpenSansExtraBoldItalic";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/OpenSans-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: "InterThin";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-Thin.ttf);
}
@font-face {
  font-family: "InterLight";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-Light.ttf);
}
@font-face {
  font-family: "InterSemiBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "InterBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-Bold.ttf);
}
@font-face {
  font-family: "InterExtraBold";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "InterExtraBlack";
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  src: url(./assets/fonts/Inter-Black.ttf);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f5f7;
  font-family: "OpenSans";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
#root,
.ant-layout,
.ant-modal-root,
.ant-select,
.ant-modal,
.ant-picker-dropdown {
  font-family: "OpenSans";
}
.ant-select-options {
  font-family: "OpenSans";
}
.fc-centerButton-button {
  background-color: white !important;
    border: none !important;
}